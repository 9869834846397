import axios from "axios";
import type { Readable } from "stream";

import { DATE_SHORT_FORMAT } from "~/constants/date-time";
import { FILE_STATUS } from "~/constants/file";
import { fileSchema } from "~/schemas/file";
import type { ApplicationById } from "~/types/application";
import type { FileOriginType, FileStatusType } from "~/types/database";
import { formatFromISO } from "~/utils/datetime";
import { trpcClient } from "~/utils/trpc";

export const getKeyFromUri = (uri: string) => {
  const pathname = new URL(uri).pathname.split("/");
  return pathname[pathname.length - 1];
};

/**
 * You should use this function to upload a file to the S3.
 * It first queries the uploadUrl then adds the file metadata to the fichiers table if the file name doesn't already exist and finally puts the file in the S3.
 * This flow ensures that a file added to the S3 will be in the fichier table.
 */
const uploadFile = async ({
  application_id,
  liquidation_id,
  pdf_config_id,
  beneficiary_id,
  created_by,
  file_origin,
  status,
  file,
}: {
  application_id?: string;
  liquidation_id?: string;
  pdf_config_id?: string;
  beneficiary_id?: string;
  created_by?: string;
  file_origin: FileOriginType;
  status?: FileStatusType;
  file: File;
}) => {
  if (!created_by) throw new Error("The user has no session");

  // Get the upload url
  const { signedUrl } = await trpcClient.s3.getUploadUrl.query();
  const newUri = getKeyFromUri(signedUrl);

  // Create the file in the fichiers table
  const insert_files_one = await trpcClient.files.create.mutate({
    application_id,
    liquidation_id,
    pdf_config_id,
    beneficiary_id,
    created_by,
    file: {
      origin: file_origin,
      status: status ?? FILE_STATUS.DRAFT,
      name: file.name,
      uri: newUri,
      size: file.size,
      mime_type: file.type,
      signing_report: null,
    },
  });

  // Upload the file to the S3
  await axios.put(signedUrl, file);

  return insert_files_one;
};

const downloadUrlToFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadFile = async (uri: string, filename: string) => {
  const { signedUrl } = await trpcClient.s3.getDownloadUrl.query({ key: uri });
  const fileContent = await fetch(signedUrl);
  const url = window.URL.createObjectURL(await fileContent.blob());
  downloadUrlToFile(url, filename);
};

const downloadArrayBuffer = (buffer: Uint8Array | string, filename: string) => {
  const blob = new Blob([buffer]);
  const url = window.URL.createObjectURL(blob);
  downloadUrlToFile(url, filename);
};

const convertStreamToText = async (stream: Readable): Promise<string> => {
  const chunks: Uint8Array[] = [];
  return new Promise((resolve, reject) => {
    stream.on("data", (chunk: Uint8Array) => chunks.push(chunk));
    stream.on("end", () => resolve(Buffer.concat(chunks).toString("utf-8")));
    stream.on("error", reject);
  });
};

const getFilenameWithoutExtension = (filename: string) => filename.split(".").slice(0, -1).join(".");

const formatApplicationDataForCSV = (application?: ApplicationById) => {
  if (!application) return { tables: [[]] };

  const { liquidations, validations, subvention_projects, files, applicant, beneficiary, handler, dispositif, service, ...rest } = application;

  return {
    tables: [
      {
        Dossiers: "",
        Id: rest.id,
        "Numero de dossier": rest.numero_dossier,
        Etape: rest.step,
        "Date de soumission": formatFromISO(rest.date_soumission, DATE_SHORT_FORMAT),
        "Montant octroye": rest.amount_granted,
        "Nom du projet": rest.project_name,
        "Titre donne par WBI": rest.handler_title,
        "Controle demande": rest.control_requested,
        Controlle: rest.controlled,
        "Trop Percu": rest.has_overpayment,
        "Nom du demandeur": applicant?.full_name,
        "Nom de l'agent traitant": applicant?.full_name,
        "Nom du dispositif": dispositif?.label,
        "Debut de validite du dispositif": formatFromISO(dispositif.valid_from, DATE_SHORT_FORMAT),
        "Fin de validite du dispositif": formatFromISO(dispositif.valid_until, DATE_SHORT_FORMAT),
        Service: service?.name,
        "Organisation proprietaire du beneficiare": beneficiary?.owner_organisation_id,
        "Utilisateur proprietaire du beneficiare": beneficiary?.owner_user_id,
        "Nom du compte du beneficiare": beneficiary?.account_name,
        "Numero de compte du beneficiare": beneficiary?.account_iban,
        "Beneficiare actif": beneficiary?.is_active,
        "Date de creation du beneficiare": formatFromISO(beneficiary?.created_at, DATE_SHORT_FORMAT),
        "Date de modification du beneficiare": formatFromISO(beneficiary?.updated_at, DATE_SHORT_FORMAT),
      },
      liquidations.map((liquidation) => ({
        Liquidations: "",
        Id: liquidation.id,
        Numero: liquidation.number,
        Type: liquidation.type,
        Step: liquidation.step,
        Montant: liquidation.amount,
        "Date de creation": formatFromISO(liquidation.created_at, DATE_SHORT_FORMAT),
        "Date de paiement": formatFromISO(liquidation.payment_date, DATE_SHORT_FORMAT),
        "Cree par": liquidation.created_by_role,
      })),
      subvention_projects.map((subvention_project) => ({
        "Projets de subvention": "",
        Id: subvention_project.id,
        Type: subvention_project.type,
        Step: subvention_project.step,
        "Liquidation automatique": subvention_project.automatic_liquidation_enabled,
        "Pourcentage de liquidation automatique": subvention_project.automatic_liquidation_percentage,
        "Date de debut": formatFromISO(subvention_project.subvention_start_date, DATE_SHORT_FORMAT),
        "Date de fin": formatFromISO(subvention_project.subvention_end_date, DATE_SHORT_FORMAT),
        "Completee a": formatFromISO(subvention_project.completed_at, DATE_SHORT_FORMAT),
      })),
      subvention_projects
        .map((subvention_project) => subvention_project.budget_proposal_details)
        .flat()
        .map((budget_proposal_detail) => ({
          "Propositions budgetaires": "",
          Id: budget_proposal_detail.id,
          "Id du projet de subvention": budget_proposal_detail.subvention_project_id,
          Annee: budget_proposal_detail.n_year,
          Etablissement: budget_proposal_detail.institution,
          "Article budgetaire": budget_proposal_detail.budget_article,
          "Centre budgetaire": budget_proposal_detail.budget_center,
          "Type de frais": budget_proposal_detail.charge_types,
          "Montant octroye": budget_proposal_detail.amount_granted,
          "Nature analytique": budget_proposal_detail.analytical_nature,
          "Centre analytique": budget_proposal_detail.analytical_center,
          "Pole de competitivite": budget_proposal_detail.competitiveness_cluster,
          "Programme interregional": budget_proposal_detail.interreg_prog,
          "Code transversal": budget_proposal_detail.transversal_axis,
          "Catégorie transversale": budget_proposal_detail.transversal_category,
          "Axe NPI": budget_proposal_detail.npi_axis,
          "Programme de recherche": budget_proposal_detail.research_program,
          Commentaire: budget_proposal_detail.comments,
        })),
      files.map((file) => ({
        Fichiers: "",
        Id: file.id,
        "Nom du fichier": file.name,
        "Taille du fichier": file.size,
        Status: file.status,
        "Date de creation": formatFromISO(file.created_at, DATE_SHORT_FORMAT),
        "Cree par": file.created_by,
        "Rapport de signature": file.signing_report,
        "Type du fichier": file.mime_type,
        Uri: file.uri,
        Origin: file.origin,
      })),
      validations.map((validation) => ({
        Validations: "",
        Id: validation.id,
        "Sujet de la validation": validation.subject,
        Approuvé: !!validation.approved,
        "Contenu de la validation": validation.content,
        "Cree par": validation.user?.full_name,
      })),
      [
        {
          Utilisateurs: "",
          Id: applicant?.id,
          Role: "Demandeur",
          Prénom: applicant?.first_name,
          Nom: applicant?.last_name,
          Email: applicant?.email,
          Téléphone: applicant?.phone_number,
          Genre: applicant?.gender,
          Address: applicant?.address?.address,
          Localite: applicant?.address?.locality,
          "Code postal": applicant?.address?.postal_code,
          Pays: applicant?.address?.country,
        },
        {
          Utilisateurs: "",
          Id: handler?.id,
          Role: "Agent traitant",
          Prénom: handler?.first_name,
          Nom: handler?.last_name,
          Email: handler?.email,
          Téléphone: handler?.phone_number,
          Genre: handler?.gender,
          Address: handler?.address?.address,
          Localite: handler?.address?.locality,
          "Code postal": handler?.address?.postal_code,
          Pays: handler?.address?.country,
        },
      ],
    ],
  };
};

const extractFileIdsRecursive = (obj: any): string[] => {
  let ids: string[] = [];

  if (Array.isArray(obj)) {
    for (const item of obj) {
      ids = ids.concat(extractFileIdsRecursive(item));
    }
  } else if (obj && typeof obj === "object") {
    const parseResult = fileSchema.safeParse(obj);
    if (parseResult.success) {
      ids.push(parseResult.data.id ?? "");
    }

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        ids = ids.concat(extractFileIdsRecursive(obj[key]));
      }
    }
  }

  return ids;
};

export {
  uploadFile,
  downloadArrayBuffer,
  downloadFile,
  getFilenameWithoutExtension,
  convertStreamToText,
  formatApplicationDataForCSV,
  extractFileIdsRecursive,
};
